export const debtsEvolutionString = [
  "Ajuntament",
  "Mataro Audiovisual",
  "Digitial Mataro Maresme",
  "Consorci Residus",
  "PUMSA",
  "Grup Tecnocampus",
  "AMSA",
  "Deute FCC",
  "Porta Laietana",
  "Altres pie messa",
  "Deute Viu Grup Ajuntament",
  "Deute Viu Sector Administracions Públiques",
  "Rati deute viu %"
];
